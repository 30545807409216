import { Canvas } from "@react-three/fiber";
import { useFBX } from "@react-three/drei";
import { XR, ARButton } from "@react-three/xr";

const Scene = () => {
  const fbx = useFBX("Ch46_nonPBR.fbx");
  return <primitive object={fbx} scale={0.02} position={[0, 0, -5]} />;
};

function Beeld() {
  return (
    <div>
      <ARButton />
      <Canvas>
        <XR referenceSpace="local-floor">
          <ambientLight color="yellow" intensity={0.5} />
          <directionalLight position={[10, 10, 5]} />
          <directionalLight position={[1, 10, 10]} intensity={0.1} />
          <Scene></Scene>
        </XR>
      </Canvas>
    </div>
  );
}

export default Beeld;
